import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: 'https://6d0c010b011443afa7a3b0f6da25e855@o465921.ingest.sentry.io/5513977',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: __ENV__ === 'production' || __ENV__ === 'staging' ? 0.3 : 0.0,
  environment: __ENV__,
  release: __RELEASE__,
});

const Rails = require('@rails/ujs');
Rails.start();

import jQuery from 'jquery'

window.jQuery = jQuery;
window.$ = jQuery;

import 'bootstrap';

require('admin-lte');
require('bootstrap-datepicker');
require('bootstrap-datepicker/dist/locales/bootstrap-datepicker.ja.min');
require('select2/dist/js/select2.full');
require('select2/dist/js/i18n/ja');
require('datatables.net');
require('datatables.net-bs4');
require('jquery-datetimepicker');

jQuery.datetimepicker.setLocale('ja');

// css
import './application.scss';
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

// images
require.context('./images', true);
